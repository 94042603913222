/** Main **/
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
